const keepOnlyUpcaseLettersAndBlankSpace = text => {
  return text.replace(/[^A-zÀ-ú'\s]+/g, '')
}

const keepOnlyNumbers = text => {
  return text.replace(/\D/g, '')
}

const limit = (text, max) => {
  return `${text}`.substring(0, max)
}

const removeBlankSpaces = text => {
  return text.replace(/\s/g, '')
}

const upcase = text => {
  return `${text}`.toUpperCase()
}

const lowcase = text => {
  return `${text}`.toLowerCase()
}

const strings = {
  keepOnlyUpcaseLettersAndBlankSpace,
  keepOnlyNumbers,
  limit,
  removeBlankSpaces,
  upcase,
  lowcase,
}
export default strings
