const constants = {
  app: {
    name: 'Inscrição Chamada Escolar',
  },
  api: {
    currentVersion: '/api/v1',
    address: {
      searchByCityAndTerm: (cityId, term) =>
        `/addresses/search?city_id=${cityId}&q=${term}`,
      states: '/addresses/states',
      cities: id => `/addresses/cities?state_id=${id}`,
    },
    customer: {
      cities: '/customer/cities',
    },
    publicCall: {
      cities: ref => `/public_call/cities?degree_ref=${ref}`,
      curriculums: (degree, school, shift, exceptOfferIds) =>
        `/public_call/curriculums?degree_ref=${degree}&school_ref=${school}&shift=${shift}&except_offer_ids=${exceptOfferIds}`,
      degrees: '/public_call/degrees',
      info: '/public_call/info',
      questions: '/public_call/questions',
      quizQuestions: '/public_call/quiz_questions',
      schools: (degree, city, exceptOfferIds) =>
        `/public_call/schools?degree_ref=${degree}&city_ref=${city}&except_offer_ids=${exceptOfferIds}`,
      schoolsQuestions: '/public_call/schools_questions',
      shifts: (degree, school, exceptOfferIds) =>
        `/public_call/shifts?degree_ref=${degree}&school_ref=${school}&except_offer_ids=${exceptOfferIds}`,
    },
    subscription: {
      get: (cpf, cpfResponsible) =>
        `/subscriptions/search?cpf=${cpf}&r_cpf=${cpfResponsible}`,
      create: '/subscriptions',
      update: uuid => `/subscriptions/${uuid}`,
      pdf: uuid => `/subscriptions/${uuid}/pdf`,
      validate: '/subscriptions/validate',
    },
    renewEnrollment: {
      cancel: groupStudentId =>
        `/renew_enrollments/cancel?group_student_id=${groupStudentId}`,
      login: '/renew_enrollments/login',
      nextOffers: groupStudentId =>
        `/renew_enrollments/next?group_student_id=${groupStudentId}`,
      pdf: groupStudentId =>
        `/renew_enrollments/pdf?group_student_id=${groupStudentId}`,
      create: '/renew_enrollments',
    },
  },
  params: {
    keys: {
      authUtils: {
        _session: '_scwauth',
      },
    },
    recaptcha: '6LdOyMsoAAAAAEkabHLpw8hNmQAM-2zIV8esKfKy',
    sentry: {
      dsn: 'https://0dcbc7e90ca626feae278542967d0a8e@o7645.ingest.sentry.io/4506310635749376',
    },
  },
  routes: {
    home: {
      key: 'home',
      title: 'Início',
      path: '/',
    },
    instructions: {
      key: 'instructions',
      title: 'Instruções',
      path: '/instrucoes',
    },
    subscriptions: {
      search: {
        key: 'search-subscription',
        title: 'Consultar Inscrição',
        path: '/consultar',
      },
      create: {
        key: 'create-subscription',
        title: 'Nova Inscrição',
        path: '/nova',
      },
    },
    renewEnrollments: {
      forgotPassword: {
        key: 'renew-enrollment-forgot-password',
        title: 'Esqueci minha senha',
        path: '/rematricula/esqueci-minha-senha',
      },
      request: {
        key: 'renew-enrollment',
        title: 'Solicitar Rematrícula',
        path: '/rematricula',
      },
    },
  },
  strings: {
    placeholders: {
      error: {
        text: 'Falha ao obter as informações, tente novamente',
        button: 'Tentar novamente',
      },
      loading: {
        text: 'Carregando...',
      },
      notFound: {
        text: 'Desculpe, a página solicitada não pôde ser encontrada...',
        button: 'Ir para página inicial',
      },
    },
    screens: {
      schedules: {
        create: {
          progress: (current, total) => `Etapa ${current} de ${total}`,
        },
      },
    },
  },
}

export default constants
